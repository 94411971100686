import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { MyToasterService } from '../../shared/mytoaster.service';
import { HttpClient, HttpResponse, HttpHeaders }    from '@angular/common/http';
@Component({
  selector:'app-header',
  templateUrl:'./header.component.html',
  styleUrls: ['./header.component.css']

})
export class HeaderComponent implements OnInit {
  admissionSetting : number;
  isLoggedIn:boolean = false;


  register: FormGroup;
  login: FormGroup;
  formData = new FormData();
  initial_details: any;
  isRegistered: boolean = true;
  isSignin: boolean = false;
  token: string;
  inApplyPage: boolean= false;


  constructor(
  private formBuilder: FormBuilder,
  private http: HttpClient,
  private apiService: ApiService,
  private toaster: MyToasterService,
  private router: Router) {
  }

  ngOnInit() {
  // this.getSettings();
  console.log(this.router.url);
  this.isLoggedIn = this.apiService.checkLogin();
  console.log(this.isLoggedIn);

  this.login = this.formBuilder.group({
    username: ['', [Validators.required]],
    password: ['', [Validators.required]]
    });

}


  getSettings() {
    this.apiService.getSettings('Admission').subscribe(
      (data: any) => {
        this.admissionSetting = data['settings'].status;
        console.log(this.admissionSetting);
        // console.log(this.classes);
        },
        err => {
          console.log(err.error);
       }
      );
  }

  checkRoute() {
    console.log(this.router.url);
    if(this.router.url == '/apply') {
      this.inApplyPage = true;
    }
  }

  onLogin() {
    const username = this.login.controls.username.value;
    const password = this.login.controls.password.value;
    if(!username || username == '') {
      this.toaster.showToast('', 'Please fill the mandatory fields', 'error');
      return;
    }
    if(!password || password == '') {
      this.toaster.showToast('', 'Please fill the mandatory fields', 'error');
      return;
    }
    this.apiService.login(username, password).subscribe(
      (data: any) => {
        console.log(data);
        this.token = data.token;
        console.log(this.token);
        // JSON.stringify(this.token)
        localStorage.setItem('student_token', this.token);
        this.router.navigate(['dashboard']);
        },
        err => {
          this.toaster.showToast('',err.error.message, 'error');
       }
      );
  }


  logout() {
    this.apiService.logout();
  }
  
}
