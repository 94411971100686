import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
// declare  var jQuery:  any;
// import * as $ from ‘jquery’;
declare var $: any

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
photos : any;
banners: any;
mentors: any;
notices : any;
testimonies : any;
galleryPhoto : any;
baseUrl : string;
photoPage : number = 1;
admissionSetting : number;
  constructor(private apiService: ApiService) {
    this.baseUrl = apiService.getBaseUrl();
    console.log(this.baseUrl);
    this.apiService.isLoggedIn = false;
   }

  ngOnInit() {

    $(function() {
      $('.gallery-grid a').Chocolat();
    });

                  $(document).ready(function() {
                    $("#owl-demo").owlCarousel({
                      items : 1,
                      lazyLoad : true,
                      autoPlay : true,
                      navigation : false,
                      navigationText :  false,
                      pagination : true,
                    });
                  });

        // $(function () {
        //   $("#slider").responsiveSlides({
        //     auto: true,
        //     pager:false,
        //     nav: true,
        //     speed: 1000,
        //     namespace: "callbacks",
        //     before: function () {
        //       $('.events').append("<li>before event fired.</li>");
        //     },
        //     after: function () {
        //       $('.events').append("<li>after event fired.</li>");
        //     }
        //   });
        // });

    $(document).ready(function($) {
    $(".scroll").click(function(event){
      event.preventDefault();
      $('html,body').animate({scrollTop:$(this.hash).offset().top},1000);
    });
    });

      $(document).ready(function() {
      $().UItoTop({ easingType: 'easeOutQuart' });
      });

      this.getPhotos();
      // this.getSettings();
      this.getNotices();
      this.getBanners();
      this.getMentors();
      // this.getTestimonies();
  }

  getBanners() {
    this.apiService.getBanners().subscribe(
      (data: any) => {
        this.banners = data['banners'];
        console.log(this.banners);
        },
        err => {
          console.log(err.error);
       }
      );
  }

  getMentors() {
    this.apiService.getMentors().subscribe(
      (data: any) => {
        this.mentors = data['mentors'];
        console.log(this.mentors);
        },
        err => {
          console.log(err.error);
       }
      );
  }

  getNotices() {
    this.apiService.getNotices().subscribe(
      (data: any) => {
        this.notices = data['notices'];
        console.log(this.notices);
        // console.log(this.classes);
        },
        err => {
          console.log(err.error);
       }
      );
  }

  // getTestimonies() {
  //   this.apiService.getTestimonies().subscribe(
  //     (data: any) => {
  //       this.testimonies = data['testimonies'];
  //       console.log(this.testimonies);
  //       },
  //       err => {
  //         console.log(err.error);
  //      }
  //     );
  // }


  getSettings() {
    this.apiService.getSettings('Admission').subscribe(
      (data: any) => {
        this.admissionSetting = data['settings'].status;
        console.log(this.admissionSetting);
        // console.log(this.classes);
        },
        err => {
          console.log(err.error);
       }
      );
  }

  getPhotos() {
    this.apiService.getPhotos(this.photoPage).subscribe(
      (data: any) => {
        this.photos = data['photos'].data;
        console.log(this.photos);
        // console.log(this.classes);
      },
      err => {
        console.log(err.error);
     }
    );
  }

  openModal(photo) {
    this.galleryPhoto = photo;
  }

}
