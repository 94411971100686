import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRoute, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private authService : AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let value = this.authService.isAuthenticated()
    if (!value) {
      this.router.navigateByUrl("/signin")
    }
    return value

    // return this.auth.getUser()
    //  .subscribe(data => {
    //    if(!data) {
    //      this.router.navigateByUrl("/login");
    //      return false;
    //    }
    //    else {
    //      return true;
    //    }
    //  });
  }


}
