import { Injectable } from '@angular/core';
import { RecipeService } from '../recipes/recipe.service';
import { HttpClient, HttpResponse }    from '@angular/common/http';

@Injectable()
export class DataStorageService {
  constructor(private http: HttpClient, private recipeService: RecipeService) {}

  storeRecipe() {
    return this.http.put('https://ng-recipe-book.firebaseio.com/recipes.json',this.recipeService.getRecipes());
  }
  getRecipe() {
    // this.http.get('https://ng-recipe-book.firebaseio.com/recipes.json')
    // .map(
    //   (recipes) => {
    //     return recipes;
    //   }
    // )
  }
 }
