import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../api.service';
import { MyToasterService } from '../../../shared/mytoaster.service';
import { FormArray, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { HttpClient, HttpResponse, HttpHeaders }    from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { faCoffee,faEdit } from '@fortawesome/free-solid-svg-icons';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { exit } from 'process';

@Component({
  selector: 'app-apply',
  templateUrl: './apply.component.html',
  styleUrls: ['./apply.component.css']
})
export class ApplyComponent implements OnInit {
contact_no: any;
student: any;
// courses: any;
// classes: any;
firstFormGroup: FormGroup;
secondFormGroup: FormGroup;
thirdFormGroup: FormGroup;
genders = ['male', 'female'];
faEdit = faEdit;
photo: File = null;
parentSig: File = null;
previewUrl:any = null;
previewBirthUrl:any = null;
previewParentDocUrl:any = null;
previewParentSigUrl:any = null;
formData = new FormData();
checked = false;
transferCertificate: File = null;
previewTransferCertificateUrl:any = null;

birth_cer: File = null;

parent_proof: File = null;

booleanData = [
  {
  'name': 'No',
  'value': 0
},
{
'name': 'Yes',
'value': 1
}
];
temp_students: any;

  constructor (
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private route :ActivatedRoute,
    private apiService: ApiService,
    private toaster: MyToasterService,
    private router: Router){
    let storedToken:string = localStorage.getItem('student_token');
    // if(storedToken && storedToken != '') {
    //   this.verifyToken(storedToken);
    // }
    // else {
    //   this.router.navigate(['admission']);
    // }
 }

  ngOnInit() {
    // this.apiService.getClasses().subscribe(
    //   (data: object) => {
    //     console.log(data);
    //     if(data['classes']) {
    //       this.classes = data['classes'];
    //       console.log(this.classes);
    //     }
    //   },
    //   err => {
    //     console.log(err.error);
    //  }
    // );


    // this.apiService.getCourses().subscribe(
    //   (data: object) => {
    //     console.log(data);
    //     if(data['courses']) {
    //       this.courses = data['courses'];
    //       console.log(this.courses);
    //     }
    //   },
    //   err => {
    //     console.log(err.error);
    //  }
    // );



    this.firstFormGroup = this.formBuilder.group({
    id: [0, Validators.required],
    name: ['', Validators.required],
    f_name: ['', Validators.required],
    f_occupation: ['', Validators.required],
    m_name: ['', Validators.required],
    natonality: ['', Validators.required],
    religion: ['', Validators.required],
    community: ['', Validators.required],
    caste: ['', Validators.required],
    d_o_b: ['', Validators.required],
    gender: [this.genders[0], Validators.required],
    contact_no: ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
    // qualification: ['', Validators.required],
    // course_id: ['', Validators.required],
    // class_id: ['', Validators.required],
    last_school: [''],
    previous_class: [''],
    cause_of_leaving: [''],
    tc_date: [''],
    tc_no: [''],
    postal_address: ['', Validators.required]
    });


    this.secondFormGroup = this.formBuilder.group({
      matric_board: [''],
      matric_yop: [''],
      matric_div: [''],
      matric_per: [''],
      // twelveth_board: [''],
      // twelveth_yop: [''],
      // twelveth_div: [''],
      // twelveth_per: [''],
      other_board: [''],
      other_yop: [''],
      other_div: [''],
      other_per: ['']
    });


    this.thirdFormGroup = this.formBuilder.group({
      photo: [''],
      parent_sig: [''],
      photo_temp: [null],
      parent_sig_temp: [null],
      birth_cer: [null],
      parent_proof: [null],
      tc_temp: [null]
    });

    this.student = [];


  }

  // verifyToken(storedToken: string) {
  //   this.apiService.verifyToken(storedToken).subscribe(
  //     (data: any) => {
  //         this.student = data.student;
  //         this.apiService.isLoggedIn = true;
  //         console.log(this.student);
  //         this.onInitStudent(this.student);
  //       },
  //       err => {
  //         this.toaster.showToast('',err.error.message, 'error');
  //         this.router.navigate(['admission']);
  //      }
  //     );
  // }


 

  // onInitStudent(student) {
  //   let gender = null;
  //   let is_disable = null;
  //   let is_bpl = null;
  //   if(!student.gender) {
  //    gender =  this.genders[0];
  //   }
  //   else {
  //     gender =  student.gender;
  //   }
  //   if(!student.is_disable) {
  //    is_disable =  this.booleanData[0].value;
  //   }
  //   else {
  //     is_disable =  student.is_disable;
  //   }
  //   if(!student.is_bpl) {
  //    is_bpl =  this.booleanData[0].value;
  //   }
  //   else {
  //     is_bpl =  student.is_bpl;
  //   }
  //   console.log(is_bpl);
  //   console.log(is_disable);
  //   this.firstFormGroup = this.formBuilder.group({
  //   id: [student.id, Validators.required],
  //   name: [student.name, Validators.required],
  //   f_name: [student.f_name, Validators.required],
  //   f_occupation: [student.f_occupation, Validators.required],
  //   d_o_b: [student.d_o_b, Validators.required],
  //   gender: [gender, Validators.required],
  //   contact_no: [student.contact_no, Validators.required],
  //   qualification: [student.qualification, Validators.required],
  //   postal_address: [student.postal_address]
  //   });


  //   this.secondFormGroup = this.formBuilder.group({
  //   permanent_address: [student.permanent_address, Validators.required],
  //   same_as_permanent: [student.same_as_permanent, Validators.required],
  //   present_address: [student.present_address, Validators.required],
  //   last_school: [student.last_school],
  //   previous_class: [student.previous_class],
  //   cause_of_leaving: [student.cause_of_leaving],
  //   tc_date: [student.tc_date],
  //   tc_no: [student.tc_no]
  //   });


  //   this.thirdFormGroup = this.formBuilder.group({
  //     bpl_c: [student.bpl_c],
  //     photo: [student.photo],
  //     tc: [student.tc],
  //     disable_c: [student.disable_c],
  //     parent_sig: [student.parent_sig],
  //     bpl_c_Temp: [null],
  //     tc_temp: [null],
  //     disable_c_temp: [null],
  //     date: [student.date, Validators.required]
  //   });

  //   if(student.photo) {
  //     this.thirdFormGroup.addControl('photo_temp', new FormControl(null));
  //   }
  //   else {
  //     this.thirdFormGroup.addControl('photo_temp', new FormControl(null, Validators.required));

  //   }
  //   if(student.parent_sig) {
  //     this.thirdFormGroup.addControl('parent_sig_temp', new FormControl(null));

  //   }
  //   else {
  //     this.thirdFormGroup.addControl('parent_sig_temp', new FormControl(null, Validators.required));

  //   }
  //   this.previewUrl = this.apiService.getBaseUrl()+'/'+student.photo;
  //   this.previewParentSigUrl = this.apiService.getBaseUrl()+'/'+student.parent_sig;
  // }


  // onSameAsPermanent(values: any) {
  //   if(values.currentTarget.checked) {
  //     this.secondFormGroup.patchValue({
  //       'present_address': this.secondFormGroup.get('permanent_address').value
  //     });
  //   }
  //   else {
  //     this.secondFormGroup.patchValue({
  //       'present_address': ''
  //     });
  //   }
  // }

  onFirstSubmit() {
    if(!this.firstFormGroup.valid) {
      this.toaster.showToast('','Please fill the mandatory fields', 'error');
      return;
    }
    console.log(this.firstFormGroup.value);
    // this.apiService.submitFirstForm(this.firstFormGroup.value).subscribe(
    //   (data: object) => {
    //     this.student = data['student'];
    //   },
    //   err => {
    //     console.log(err.error);
    //     this.toaster.showToast('',err.error.message, 'error');
    //     this.router.navigate(['admission']);
    //  }
    // );
  }
  onSecondSubmit() {
    if(!this.secondFormGroup.valid) {
      this.toaster.showToast('','Please fill the mandatory fields', 'error');
      return;
    }
    console.log(this.secondFormGroup.value);
    // this.apiService.submitSecondForm(this.student['id'],this.secondFormGroup.value).subscribe(
    //   (data: object) => {
    //     this.student = data['student'];
    //   },
    //   err => {
    //     console.log(err.error);
    //     this.toaster.showToast('',err.error.message, 'error');
    //     this.router.navigate(['admission']);
    //  }
    // );
  }
  onThirdSubmit() {
    let formData = new FormData();
    if(this.thirdFormGroup.get('photo_temp').value) {
      formData.append('photo_temp', this.thirdFormGroup.get('photo_temp').value);
    }
    if(this.thirdFormGroup.get('parent_sig_temp').value) {
      formData.append('parent_sig_temp', this.thirdFormGroup.get('parent_sig_temp').value);
    }
    if(this.thirdFormGroup.get('birth_cer').value) {
      formData.append('birth_cer', this.thirdFormGroup.get('birth_cer').value);
    }
    if(this.thirdFormGroup.get('parent_proof').value) {
      formData.append('parent_proof', this.thirdFormGroup.get('parent_proof').value);
    }
    if(!this.thirdFormGroup.valid) {
      this.toaster.showToast('','Please fill the mandatory fields', 'error');
      return;
    }
    
    // formData.append('token', this.apiService.getToken());
    // this.http.post(this.apiService.getBaseUrl()+'/api/student/thirdForm/'+this.student['id'], formData)
    //   .subscribe(data => {
    //     console.log(data);
    //     this.student = data['student'];
    //   },
    //   err => {
    //       console.log(err.error);
    //       this.toaster.showToast('',err.error.message, 'error');
    //       this.router.navigate(['admission']);
    //    });
    // this.apiService.submitThirdForm(this.student['id'],formData).subscribe(
    //   (data: object) => {
    //     this.student = data['student'];
    //   },
    //   err => {
    //     console.log(err.error);
    //  }
    // );
  }

  finalSubmit(stepper) {
    let formData = new FormData();
    if(this.thirdFormGroup.get('photo_temp').value) {
      formData.append('photo_temp', this.thirdFormGroup.get('photo_temp').value);
    }
    if(this.thirdFormGroup.get('parent_sig_temp').value) {
      formData.append('parent_sig_temp', this.thirdFormGroup.get('parent_sig_temp').value);
    }
    if(this.thirdFormGroup.get('tc_temp').value) {
      formData.append('tc_temp', this.thirdFormGroup.get('tc_temp').value);
    }

    if(this.thirdFormGroup.get('birth_cer').value) {
      formData.append('birth_cer', this.thirdFormGroup.get('birth_cer').value);
    }
    if(this.thirdFormGroup.get('parent_proof').value) {
      formData.append('parent_proof', this.thirdFormGroup.get('parent_proof').value);
    }
    formData.append('contact_no', this.firstFormGroup.get('contact_no').value);
    formData.append('d_o_b', this.firstFormGroup.get('d_o_b').value);
    formData.append('f_name', this.firstFormGroup.get('f_name').value);
    formData.append('f_occupation', this.firstFormGroup.get('f_occupation').value);
    formData.append('gender', this.firstFormGroup.get('gender').value);
    formData.append('name', this.firstFormGroup.get('name').value);
    formData.append('last_school', this.firstFormGroup.get('last_school').value);
    formData.append('previous_class', this.firstFormGroup.get('previous_class').value);
    formData.append('cause_of_leaving', this.firstFormGroup.get('cause_of_leaving').value);
    formData.append('tc_date', this.firstFormGroup.get('tc_date').value);
    formData.append('tc_no', this.firstFormGroup.get('tc_no').value);
    formData.append('m_name', this.firstFormGroup.get('m_name').value);
    formData.append('natonality', this.firstFormGroup.get('natonality').value);
    formData.append('religion', this.firstFormGroup.get('religion').value);
    formData.append('community', this.firstFormGroup.get('community').value);
    formData.append('caste', this.firstFormGroup.get('caste').value);
    // formData.append('course_id', this.firstFormGroup.get('course_id').value);
    formData.append('postal_address', this.firstFormGroup.get('postal_address').value);
    // formData.append('qualification', this.firstFormGroup.get('qualification').value);
    formData.append('matric_board', this.secondFormGroup.get('matric_board').value);
    formData.append('matric_div', this.secondFormGroup.get('matric_div').value);
    formData.append('matric_per', this.secondFormGroup.get('matric_per').value);
    formData.append('matric_yop', this.secondFormGroup.get('matric_yop').value);
    formData.append('other_board', this.secondFormGroup.get('other_board').value);
    formData.append('other_div', this.secondFormGroup.get('other_div').value);
    formData.append('other_per', this.secondFormGroup.get('other_per').value);
    formData.append('other_yop', this.secondFormGroup.get('other_yop').value);
    // formData.append('twelveth_board', this.secondFormGroup.get('twelveth_board').value);
    // formData.append('twelveth_div', this.secondFormGroup.get('twelveth_div').value);
    // formData.append('twelveth_per', this.secondFormGroup.get('twelveth_per').value);
    // formData.append('twelveth_yop', this.secondFormGroup.get('twelveth_yop').value);
    if(!this.checked) {
      this.toaster.showToast('Alert !!!',"Please accept Terms & Conditions first", 'error');
      return;
    }
    this.apiService.finalSubmit(formData).subscribe(
      (data: object) => {
        console.log(data);
        // this.toaster.showToast('',data['message'], 'success');
        this.toaster.showSuccessWithTimeout(data['message'], 'success',  12000);
        this.router.navigate(['apply'])
        .then(() => {
          setTimeout(
            function() 
            {
              stepper.reset();
            }, 12000);
          
        });
      },
      err => {
        console.log(err.error);
        this.toaster.showToast('',err.error.message, 'error');
        // this.router.navigate(['apply']);
     }
    );
  }




  fileProgress(fileInput: any) {
     this.photo = <File>fileInput.target.files[0];
     // const file = (event.target as HTMLInputElement).files[0];
     this.thirdFormGroup.patchValue({
       photo_temp: this.photo
     });
     console.log(this.thirdFormGroup);
     this.thirdFormGroup.get('photo_temp').updateValueAndValidity()
     this.preview();
     console.log(this.thirdFormGroup);
  }
  preview() {
   var mimeType = this.photo.type;
   if (mimeType.match(/image\/*/) == null) {
     return;
   }

   var reader = new FileReader();
   reader.readAsDataURL(this.photo);
   reader.onload = (_event) => {
     this.previewUrl = reader.result;

   }
  }
  fileParentSigProgress(fileInput: any) {
     this.parentSig = <File>fileInput.target.files[0];
     // const file = (event.target as HTMLInputElement).files[0];
     this.thirdFormGroup.patchValue({
       parent_sig_temp: this.parentSig
     });
     this.thirdFormGroup.get('parent_sig_temp').updateValueAndValidity()
     this.parentSigPreview();
  }


  fileBirthProgress(fileInput: any) {
    this.birth_cer = <File>fileInput.target.files[0];
    // const file = (event.target as HTMLInputElement).files[0];
    this.thirdFormGroup.patchValue({
      birth_cer: this.birth_cer
    });
    this.thirdFormGroup.get('birth_cer').updateValueAndValidity()
    this.birthPreview();
 }
 birthPreview() {
  var mimeType = this.birth_cer.type;
  if (mimeType.match(/image\/*/) == null) {
    return;
  }

  var reader = new FileReader();
  reader.readAsDataURL(this.birth_cer);
  reader.onload = (_event) => {
    this.previewBirthUrl = reader.result;
  }
 }


 fileParentDocProgress(fileInput: any) {
  this.parent_proof = <File>fileInput.target.files[0];
  // const file = (event.target as HTMLInputElement).files[0];
  this.thirdFormGroup.patchValue({
    parent_proof: this.parent_proof
  });
  this.thirdFormGroup.get('parent_proof').updateValueAndValidity()
  this.parentProofPreview();
}
parentProofPreview() {
var mimeType = this.parent_proof.type;
if (mimeType.match(/image\/*/) == null) {
  return;
}

var reader = new FileReader();
reader.readAsDataURL(this.parent_proof);
reader.onload = (_event) => {
  this.previewParentDocUrl = reader.result;
}
}

  parentSigPreview() {
   var mimeType = this.parentSig.type;
   if (mimeType.match(/image\/*/) == null) {
     return;
   }

   var reader = new FileReader();
   reader.readAsDataURL(this.parentSig);
   reader.onload = (_event) => {
     this.previewParentSigUrl = reader.result;
   }
  }
  // fileDisableCertificateProgress(fileInput: any) {
  //    this.disableCertificate = <File>fileInput.target.files[0];
  //    // const file = (event.target as HTMLInputElement).files[0];
  //    this.thirdFormGroup.patchValue({
  //      disable_c_temp: this.disableCertificate
  //    });
  //    this.thirdFormGroup.get('disable_c_temp').updateValueAndValidity()
  //    this.disableCertificatePreview();
  // }

  // disableCertificatePreview() {
  //  var mimeType = this.disableCertificate.type;
  //  if (mimeType.match(/image\/*/) == null) {
  //    return;
  //  }

  //  var reader = new FileReader();
  //  reader.readAsDataURL(this.disableCertificate);
  //  reader.onload = (_event) => {
  //    this.previewDisableCertificateUrl = reader.result;
  //  }
  // }




  // fileBplCertificateProgress(fileInput: any) {
  //    this.bplCertificate = <File>fileInput.target.files[0];
  //    console.log(this.bplCertificate);
  //    this.thirdFormGroup.patchValue({
  //      bpl_c_Temp: this.bplCertificate
  //    });
  //    console.log(this.thirdFormGroup.value);
  //    this.thirdFormGroup.get('bpl_c_Temp').updateValueAndValidity()
  //    this.bplCertificatePreview();
  // }

  // bplCertificatePreview() {
  //  var mimeType = this.bplCertificate.type;
  //  if (mimeType.match(/image\/*/) == null) {
  //    return;
  //  }

  //  var reader = new FileReader();
  //  reader.readAsDataURL(this.bplCertificate);
  //  reader.onload = (_event) => {
  //    this.previewBplCertificateUrl = reader.result;
  //  }
  // }
  fileTransferCertificateProgress(fileInput: any) {
     this.transferCertificate = <File>fileInput.target.files[0];
     // const file = (event.target as HTMLInputElement).files[0];
     this.thirdFormGroup.patchValue({
       tc_temp: this.transferCertificate
     });
     this.thirdFormGroup.get('tc_temp').updateValueAndValidity()
     this.transferCertificatePreview();
  }
  transferCertificatePreview() {
   var mimeType = this.transferCertificate.type;
   if (mimeType.match(/image\/*/) == null) {
     return;
   }

   var reader = new FileReader();
   reader.readAsDataURL(this.transferCertificate);
   reader.onload = (_event) => {
     this.previewTransferCertificateUrl = reader.result;
   }
  }


  // showPreview(event) {
  //   const file = (event.target as HTMLInputElement).files[0];
  //   this.uploadForm.patchValue({
  //     avatar: file
  //   });
  //   this.uploadForm.get('avatar').updateValueAndValidity()
  //
  //   // File Preview
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     this.imageURL = reader.result as string;
  //   }
  //   reader.readAsDataURL(file)
  // }

  // onPhotoSubmit() {
  //    this.formData.append('photo', this.photo);
  //    console.log(this.formData);
  //    this.http.post('url/to/your/api', this.formData)
  //      .subscribe(res => {
  //        console.log(res);
  //        alert('SUCCESS !!');
  //      })
  // }




}
