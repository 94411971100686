import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders }    from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  token:any;
  isLoggedIn= false;
  constructor(private http: HttpClient) {}

  signin(credentials: {email: string, password: string }) {
    return this.http.post('http://localhost:8000/api/login',credentials);
  }

  getCurrentUser() {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer '+this.token);
    return this.http.get('http://localhost:8000/api/getAuthenticatedUser', {headers});
  }


  setLoggedIn(value) {
    this.isLoggedIn = value;
  }
  isAuthenticated(): boolean {
    return this.isLoggedIn;
  }
  getAccessToken() {
    const token = localStorage.getItem("token");
    return token;
  }

 }
