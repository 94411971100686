import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
  photos : any;
  galleryPhoto : any;
  baseUrl : string;
  photoPage : number = 1;
  noPhotosLeft : boolean = false;
  constructor(private apiService: ApiService) {
    this.baseUrl = apiService.getBaseUrl();
    console.log(this.baseUrl);
   }

  ngOnInit() {
    this.getPhotos();

}

getPhotos() {
  this.apiService.getPhotos(this.photoPage).subscribe(
    (data: any) => {
      this.photos = data['photos'].data;
      console.log(this.photos);
      // console.log(this.classes);
      },
      err => {
        console.log(err.error);
     }
    );
  }
  loadMorePhotos() {
    this.photoPage++;
    this.apiService.getPhotos(this.photoPage).subscribe(
      (data: any) => {
        if(data['photos'].data.length == 0) {
          this.noPhotosLeft = true;
        }
        else {
          this.photos = this.photos.concat(data['photos'].data);
        }

        console.log(this.photos);
        // console.log(this.classes);
        },
        err => {
          console.log(err.error);
       }
      );
    }

    openModal(photo) {
      this.galleryPhoto = photo;
    }
}
