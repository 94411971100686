import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from '../api.service';
import { MyToasterService } from '../../shared/mytoaster.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpClient, HttpResponse, HttpHeaders }    from '@angular/common/http';

@Component({
  selector: 'app-admission',
  templateUrl: './admission.component.html',
  styleUrls: ['./admission.component.css']
})
export class AdmissionComponent implements OnInit {
  register: FormGroup;
  login: FormGroup;
  formData = new FormData();
  initial_details: any;
  isRegistered: boolean = true;
  isSignin: boolean = false;
  token: string;

  constructor(private formBuilder: FormBuilder,
    private http: HttpClient,
    private apiService: ApiService,
    private toaster: MyToasterService,
    private router: Router
    ) {
      let storedToken:string = localStorage.getItem('student_token');
      console.log(storedToken);
      if(storedToken && storedToken != '') {
        this.verifyToken(storedToken);
      }
    }
  ngOnInit() {
    this.register = this.formBuilder.group({
    contact_no: ['', [Validators.required, Validators.pattern("[0-9]{10}")]],
    password: [{value : Math.floor(1000 + Math.random() * 9000), disabled: true}]
    });
    this.login = this.formBuilder.group({
    contact_no: ['', [Validators.required, Validators.pattern("[0-9]{10}")]],
    password: ['', [Validators.required, Validators.pattern("[0-9]{4}")]]
    });
}


verifyToken(storedToken: string) {
  this.apiService.verifyToken(storedToken).subscribe(
    (data: any) => {
        this.router.navigate(['apply']);
      },
      err => {
        this.toaster.showToast('',err.error.message, 'error');
        // this.router.navigate(['admission']);
     }
    );
}

onFirstSubmit() {
  const contact_no = this.register.controls.contact_no.value;
  const password = this.register.controls.password.value;
  if(!contact_no || contact_no == '') {
    this.toaster.showToast('', 'Please fill the mandatory fields', 'error');
    return;
  }
  if(!password || password == '') {
    this.toaster.showToast('', 'Please fill the mandatory fields', 'error');
    return;
  }
  this.apiService.createUser(contact_no, password).subscribe(
    (data: any) => {
      // this.notices = data['notices'];
      this.token = data.token;
      console.log(this.token);
      localStorage.setItem('student_token', this.token);
      this.router.navigate(['apply']);
      },
      err => {
        this.toaster.showToast('',err.error.message, 'error');
     }
    );
  // this.router.navigate(['apply'], { queryParams: {contact_no: contact_no, password: password} });
}
onLogin() {
  const contact_no = this.login.controls.contact_no.value;
  const password = this.login.controls.password.value;
  if(!contact_no || contact_no == '') {
    this.toaster.showToast('', 'Please fill the mandatory fields', 'error');
    return;
  }
  if(!password || password == '') {
    this.toaster.showToast('', 'Please fill the mandatory fields', 'error');
    return;
  }
  this.apiService.login(contact_no, password).subscribe(
    (data: any) => {
      this.token = data.token;
      console.log(this.token);
      // JSON.stringify(this.token)
      localStorage.setItem('student_token', this.token);
      this.router.navigate(['apply']);
      },
      err => {
        this.toaster.showToast('',err.error.message, 'error');
     }
    );
}

  gotoRegister() {
    this.isRegistered = true;
    this.isSignin = false;
  }

  gotoSignin() {
    this.isRegistered = false;
    this.isSignin = true;
  }
}
