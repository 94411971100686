import { EventEmitter } from '@angular/core';
import { Ingredient } from '../shared/ingredient.model';
// import { Subject } from 'rxjs/Subject';
export class ShoppingListService {
ingredientChanged = new EventEmitter<Ingredient[]>();
startedEditing = new EventEmitter<number>();
  private ingredients: Ingredient[] = [
    new Ingredient('Apples',5),
    new Ingredient('Tomatos',10)
  ];

  getIngrediants() {
    return this.ingredients.slice();
  }
  getIngrediant(index: number) {
    return this.ingredients[index];
  }

  addIngrediant(ingredient: Ingredient) {
    this.ingredients.push(ingredient);
    this.ingredientChanged.emit(this.ingredients.slice());
  }

  updateIngredient(index: number, newIngredient: Ingredient) {
      this.ingredients[index] = newIngredient;
      this.ingredientChanged.emit(this.ingredients.slice());
  }

  deleteIngredient(index: number) {
    this.ingredients.splice(index,1);
    this.ingredientChanged.emit(this.ingredients.slice());

  }
  addIngrediants(ingredients: Ingredient[]) {
    this.ingredients.push(...ingredients);
    this.ingredientChanged.emit(this.ingredients.slice());
  }
}
