import { EventEmitter,Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Recipe } from './recipe.model';
import { Ingredient } from '../shared/ingredient.model';
import { ShoppingListService } from '../shopping-list/shopping-list.service';

@Injectable()
export class RecipeService {
  recipesChanged = new Subject<Recipe[]>();
  recipeSelected = new EventEmitter<Recipe>();
  recipes: Recipe[] = [
    new Recipe('Tasty Schnitzel',
    'A super tasty Schnitzel, just awesome!',
    'assets/car.jpg',
    [new Ingredient('meat',1),
    new Ingredient('French Fries',20)
  ]),
    new Recipe('Big fat Burger',
    'What else you need to buy?',
    'assets/car.jpg',
    [
      new Ingredient('Buns',2),
      new Ingredient('Meat',1)
    ])
  ];
constructor(private shoppingListService: ShoppingListService) {}
  getRecipes() {
    return this.recipes.slice();
  }
  getRecipe(index: number) {
    return this.recipes[index];
  }

  addIngredientToShoppingList(ingredients: Ingredient[]) {
    this.shoppingListService.addIngrediants(ingredients);
  }

  addRecipe(recipe: Recipe) {
    this.recipes.push(recipe);
    this.recipesChanged.next(this.recipes.slice());
  }

  updateRecipe(index: number, newrecipe: Recipe) {
    this.recipes[index] = newrecipe;
    this.recipesChanged.next(this.recipes.slice());
  }
  deleteRecipe(index: number) {
    this.recipes.splice(index, 1);
    this.recipesChanged.next(this.recipes.slice());
  }
}


    // 'https://cdn.pixabay.com/photo/2016/12/10/21/26/food-1898194_960_720.jpg',
    // 'https://cdn.pixabay.com/photo/2016/12/10/21/26/food-1898194_960_720.jpg',
