import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { HomeComponent } from './core/home/home.component';
import { GalleryComponent } from './core/gallery/gallery.component';
import { AboutUsComponent } from './core/about-us/about-us.component';
import { AdmissionComponent } from './core/admission/admission.component';
import { ApplyComponent } from './core/admission/apply/apply.component';
import { DashboardComponent } from './core/dashboard/dashboard.component';
// import { ShoppingListComponent } from './shopping-list/shopping-list.component';


const appRoutes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'gallery', component: GalleryComponent },
  // { path: 'admission', component: AdmissionComponent },
  { path: 'apply', component: ApplyComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'recipes', loadChildren: './recipes/recipes.module#RecipesModule' },
  { path: 'shopping-list', loadChildren: './shopping-list/shopping-list.module#ShoppingListModule' },
  { path: 'signin', loadChildren: './auth/auth.module#AuthModule' },
  { path: 'portal', loadChildren: './master/master.module#MasterModule' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules})
    // RouterModule.forRoot(appRoutes, {useHash: true})
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule {

}
