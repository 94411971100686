import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  constructor(private authService: AuthService, private router: Router) {}


  ngOnInit() {
    console.log("Always loading");
    // this.authService.token = this.authService.getAccessToken();
    // console.log("b");
    // console.log(this.authService.isAuthenticated());
    // this.authService.getCurrentUser().subscribe(
    //   (data) => {
    //     console.log(data);
    //     this.authService.setLoggedIn(true);
    //     console.log("c");
    //     console.log(this.authService.isAuthenticated());
    //     localStorage.setItem('user',JSON.stringify(data['user']));
    //     this.router.navigate(['/portal']);
    //   },
    //   err => {
    //     console.log(err.error);
    //     localStorage.removeItem('user');
    //     localStorage.removeItem('token');
    //     this.authService.setLoggedIn(false);
    //  }
    // );
  }

}
