import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule, MatIconModule, MatFormFieldModule, MatButtonModule, MatInputModule, MatAutocompleteModule } from '@angular/material';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HeaderComponent } from './header/header.component';
import { SharedModule } from '../shared/shared.module';
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { AppRoutingModule } from '../app-routing.module';
import { RecipeService } from '../recipes/recipe.service';
import { ShoppingListService } from '../shopping-list/shopping-list.service';
import { DataStorageService } from '../shared/data-storage.service';
import { AuthService } from '../auth/auth.service';
import { AuthGuardService } from '../auth/authGuard.service';
import { GalleryComponent } from './gallery/gallery.component';
import { AdmissionComponent } from './admission/admission.component';
import { ApplyComponent } from './admission/apply/apply.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ApiService } from './api.service';
import { MyToasterService } from '../shared/mytoaster.service';
import {MatCheckboxModule} from '@angular/material/checkbox';
@NgModule({
  declarations: [
    HeaderComponent,
    HomeComponent,
    AboutUsComponent,
    GalleryComponent,
    AdmissionComponent,
    ApplyComponent,
    DashboardComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    FontAwesomeModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatIconModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatCheckboxModule
  ],
  exports: [
    AppRoutingModule,
    HeaderComponent
  ],
  providers: [ShoppingListService, RecipeService, DataStorageService, AuthService, AuthGuardService,ApiService,MyToasterService]

})
export class CoreModule { }
