import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders }    from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  // baseUrl : string = 'http://localhost:8000';
  baseUrl : string = 'https://admin.maqboolgarden.com';
  storedToken:string = null;
  isLoggedIn:boolean = false;
  constructor(private http: HttpClient, private router: Router) {
    this.storedToken = localStorage.getItem('student_token');
    this.isInApplyPage();
  }

  getBanners() {
    return this.http.get(this.baseUrl+'/api/home/getBanners');
  }

  getMentors() {
    return this.http.get(this.baseUrl+'/api/home/getMentors');
  }

  // getTestimonies() {
  //   return this.http.get(this.baseUrl+'/api/home/getTestimonies');
  // }

  getPhotos(photoPage) {
    // alert("abc");
    // let headers = new HttpHeaders();
    // headers = headers.set('Authorization', 'Bearer '+this.authService.getAccessToken());
    return this.http.get(this.baseUrl+'/api/home/getphotos?page='+photoPage);
  }
  getSettings(type) {
    return this.http.get(this.baseUrl+'/api/home/settings?name='+type);
  }
  getNotices() {
    return this.http.get(this.baseUrl+'/api/home/notices');
  }

  
  createUser(contact_no,password) {
    return this.http.post(this.baseUrl+'/api/home', { contact_no: contact_no,password: password });
  }
  login(username,password) {
    return this.http.post(this.baseUrl+'/api/home/studentLogin', { username: username,password: password });
  }
  verifyToken(storedToken) {
    return this.http.post(this.baseUrl+'/api/home/verifyToken', { token : storedToken });
  }

  // submitFirstForm(student) {
  //   console.log(this.storedToken);
  //   // let headers = new HttpHeaders();
  //   // headers = headers.set('Authorization', 'Bearer '+this.authService.getAccessToken());
  //   return this.http.post(this.baseUrl+'/api/student',{student: student,token:this.storedToken});
  // }

  // submitSecondForm(id,student) {
  //   return this.http.post(this.baseUrl+'/api/student/secondForm/'+id,{student: student,token:this.storedToken});
  // }
  // submitThirdForm(id,formData) {
  //   return this.http.post(this.baseUrl+'/api/student/thirdForm/'+id,{formData:formData,token:this.storedToken});
  // }
  finalSubmit(formData) {
    return this.http.post(this.baseUrl+'/api/home',formData);
  }
  getClasses() {
    // let headers = new HttpHeaders();
    // headers = headers.set('Authorization', 'Bearer '+this.authService.getAccessToken());
    return this.http.get(this.baseUrl+'/api/home/class-lists');
  }
  getCourses() {
    // let headers = new HttpHeaders();
    // headers = headers.set('Authorization', 'Bearer '+this.authService.getAccessToken());
    return this.http.get(this.baseUrl+'/api/home/course-lists');
  }

  getBaseUrl() {
    return this.baseUrl;
  }
  getToken() {
    return this.storedToken;
  }
  isInApplyPage() {
    if(this.storedToken && this.storedToken != '') {
      this.isLoggedIn = true;
    }
    else {
      this.isLoggedIn = false;
    }
    return this.isLoggedIn;
  }
  checkLogin() {
    if(this.storedToken && this.storedToken != '') {
      this.verifyToken(this.storedToken).subscribe(
        (data: any) => {
            console.log(data);
            return this.isLoggedIn = true;
          },
          err => {
            // this.toaster.showToast('',err.error.message, 'error');
            return this.isLoggedIn = false;
         }
        );
    }
    else {
      return this.isLoggedIn = false; 
    }
    return this.isLoggedIn;
  }

  logout() {
    localStorage.removeItem('student_token');
    this.router.navigate(['/']);
  }
 }
